import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Notifications from './Components/System/Notifications';
import BaseRouter from './Components/Routes/BaseRouter';
import { CosmosThemeProvider } from 'cosmos-components';
import { useSelector } from 'react-redux';
import { getAppearance } from './Selectors/ui';
import { GlobalStyle } from 'cosmos-core';

const App = () => {
  const appearance = useSelector(getAppearance);
  return (
    <div>
      <CosmosThemeProvider theme={appearance?.cosmosTheme || 'light'}>
        <GlobalStyle />
        <BrowserRouter future={{ v7_startTransition: true }}>
          <BaseRouter />
          <ToastContainer />
          <Notifications />
        </BrowserRouter>
      </CosmosThemeProvider>
    </div>
  );
};

export default App;
