import { DateTime } from 'luxon';
import { generateUrlParams } from '../../Utils';

const hostUrl = import.meta.env.VITE_APP_ADAL_HOST_URL;
const tenant = import.meta.env.VITE_APP_ADAL_TENANT;
const clientId = import.meta.env.VITE_APP_ADAL_CLIENT_ID;
const redirectUri = import.meta.env.VITE_APP_ADAL_REDIRECT_URI;

export const generateAuthorizeUrl = (options) => {
  const params = {
    response_type: options?.responseType || 'code',
    client_id: clientId,
    redirect_uri: redirectUri,
    domain_hint: 'rwe-ag.com',
    scope: 'openid email profile',
    state: JSON.stringify({
      redirect: !!options?.redirect,
      referer: options?.referer || '/',
    }),
    nonce: DateTime.now().toMillis(),
  };

  return `${hostUrl}/${tenant}/oauth2/authorize?${generateUrlParams(params)}`;
};
