import * as _ from 'lodash-es';
import * as actions from '../Actions/types';

const initialState = {
  folderId: null,
  folder: {},
  folders: [],
  documents: [],
  selectedDocuments: [],
  highlightedDocs: [],
  validationErrors: [],
  filter: {},
  filterConfig: {},
  filterOptions: {},
  searchQuery: '',
  searchProperties: [
    {
      name: 'displayname',
      valuesetName: null,
    },
    { name: 'filename', valuesetName: null },
  ],
  searchOptions: {
    isFulltext: false,
  },
  loading: false,
  paginator: {
    pageCount: 0,
    currentPage: 0,
    pageSize: 50,
  },
  orderBy: { lastmodifieddate: 'desc' },
  ready: false,
  resultsCount: 0,
  bulkUpdateProgress: 0,
  selectedFolder: null,
  folderTreeState: null,
  subtree: false,
  substructure: false,
  clipboard: [],
  options: {
    disablePagination: false,
  },
  loadingProgress: 0,
};

const updateResource = (resource, propertyName, value, multiValueIndex) => {
  if (multiValueIndex != null) {
    const originalValue = resource[propertyName];

    let parsedValue = value;
    if (Array.isArray(parsedValue)) {
      parsedValue = _.first(parsedValue) || null;
    }

    if (originalValue.length > multiValueIndex) {
      return {
        ...resource,
        [propertyName]: originalValue.map((v, idx) => {
          if (idx === multiValueIndex) {
            return parsedValue;
          }

          return v;
        }),
      };
    } else {
      return {
        ...resource,
        [propertyName]: [...originalValue, parsedValue],
      };
    }
  }

  return {
    ...resource,
    [propertyName]: value,
  };
};

const updateResourceMultiAttribute = (
  resource,
  propertyNames,
  value,
  multiValueIndex
) => {
  return _.mapValues(resource, (v, key) => {
    if (propertyNames.includes(key)) {
      if (!Array.isArray(v)) {
        return value;
      }

      // return _.remove(v, (vo, idx) => idx === multiValueIndex);

      return _.chain(v).omit(multiValueIndex).valuesIn().value();
      // return v.map((vo, idx) => {
      //   if (idx === multiValueIndex) {
      //     return value;
      //   }

      //   return vo;
      // });
    }

    return v;
  });
};

const repository = (state = initialState, action) => {
  const { documents, filter, searchQuery, paginator, orderBy, subtree } =
    initialState;
  switch (action.type) {
    case actions.repository.REQUEST_DOCUMENTS:
      return {
        ...state,
        documents: [],
        selectedDocuments: [],
        loading: true,
      };
    case actions.repository.SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload.documents,
        resultsCount: action.payload.resultsCount || 0,
        loading: false,
      };
    case actions.repository.SET_REPOSITORY_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          ...action.payload.options,
        },
      };
    case actions.repository.SET_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.filterOptions,
      };
    case actions.repository.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
        filterConfig: action.payload.filterConfig ?? state.filterConfig,
        subtree:
          action.payload.subtree == null
            ? state.subtree
            : !!action.payload.subtree,
      };
    case actions.repository.UPDATE_FILTER:
      if (action.payload.filter == null) {
        if (action.payload.options?.subtree != null) {
          return {
            ...state,
            subtree: !!action.payload.options.subtree,
          };
        }

        if (action.payload.options?.substructure != null) {
          return {
            ...state,
            substructure: !!action.payload.options.substructure,
          };
        }
      }

      if (action.payload.filter === 'foldertype') {
        return {
          ...state,
          searchQuery: '',
          searchProperties: initialState.searchProperties,
          subtree: !!action.payload.options?.subtree,
          filter: {
            foldertype: action.payload.value,
          },
        };
      }

      if (action.payload?.value?.length <= 0) {
        const { [action.payload.filter]: removed, ...newFilter } = state.filter;

        return {
          ...state,
          filter: {
            ...newFilter,
          },
          filterConfig: {
            ...state.filterConfig,
            [action.payload.filter]: action.payload.options?.filterConfig,
          },
          subtree:
            action.payload.options?.subtree == null
              ? state.subtree
              : !!action.payload.options.subtree,
        };
      }

      if (_.isPlainObject(action.payload.filter)) {
        return {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload.filter,
          },
          filterConfig: {
            ...state.filterConfig,
            [action.payload.filter]: action.payload.options?.filterConfig,
          },
          subtree:
            action.payload.options?.subtree == null
              ? state.subtree
              : !!action.payload.options.subtree,
          orderBy: action.payload.options?.orderBy || state.orderBy,
        };
      }

      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.filter]: action.payload.value,
        },
        filterConfig: {
          ...state.filterConfig,
          [action.payload.filter]: action.payload.options?.filterConfig,
        },
        subtree:
          action.payload.options?.subtree == null
            ? state.subtree
            : !!action.payload.options.subtree,
        orderBy: action.payload.options?.orderBy || state.orderBy,
      };
    case actions.repository.CLEAR_FILTER:
      return {
        ...state,
        filter: {},
        subtree: !action.payload.clearSearchQuery && state.subtree,
        searchQuery: action.payload.clearSearchQuery ? null : state.searchQuery,
      };
    case actions.repository.UPDATE_SEARCH_QUERY:
      if (
        !_.isEmpty(action.payload.searchQuery) &&
        _.isEmpty(state.searchQuery) &&
        state.selectedFolder == null
      ) {
        return {
          ...state,
          searchQuery: action.payload.searchQuery,
        };
      }

      return {
        ...state,
        searchQuery: action.payload.searchQuery,
        substructure: true,
      };
    case actions.repository.CLEAR_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: null,
      };
    case actions.repository.SET_REPOSITORY_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.repository.CLEAR_REPOSITORY_DATA:
    case actions.filterFolder.CLEAR_STORES:
      return {
        ...initialState,
        folderId: state.folderId,
      };
    case actions.repository.SET_PAGINATOR_PAGE_COUNT:
      return {
        ...state,
        paginator: {
          ...state.paginator,
          pageCount: action.pageCount,
        },
      };
    case actions.repository.SET_PAGINATOR_PAGE:
      return {
        ...state,
        paginator: {
          ...state.paginator,
          currentPage: action.payload.pageIndex,
        },
      };
    case actions.repository.SET_FOLDERS:
      return {
        ...state,
        folders: action.folders,
      };
    case actions.repository.SET_FOLDER_ID:
      return {
        ...state,
        folderId: action.payload.folderId,
      };
    case actions.repository.SET_FOLDER:
      return {
        ...state,
        folder: action.folder,
      };
    case actions.repository.SET_ORDERBY:
      return {
        ...state,
        orderBy: Object.entries(action.payload.orderBy).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key === 'fileextensionicon' ? 'contenttype' : key]: value,
          }),
          {}
        ),
      };
    case actions.repository.CLEAR_REPOSITORY_QUERY:
      return {
        ...state,
        documents,
        filter,
        searchQuery,
        paginator,
        orderBy,
        subtree,
      };
    case actions.repository.SET_SELECTED_DOCUMENTS:
      return {
        ...state,
        selectedDocuments: action.documents,
      };
    case actions.repository.SET_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.validationErrors,
      };
    case actions.repository.TOGGLE_VALIDATION_ERROR:
      return {
        ...state,
        validationErrors: _.unionBy(
          [
            {
              id: action.id,
              errors: _.chain(
                state.validationErrors.find(
                  (e) => _.isUndefined(e.id) || e.id === action.id
                )?.errors
              )
                .filter((x) => x !== action.propertyName)
                .concat(
                  _.chain([action.active ? action.propertyName : null])
                    .compact()
                    .value()
                )
                .value(),
            },
          ],
          state.validationErrors,
          'id'
        ),
      };
    // case actions.repository.SET_PROXY_FOLDER_ID:
    //   return {
    //     ...state,
    //     documents: [],
    //   };
    case actions.repository.ATTACH_LINKED_DOCUMENTS:
      return {
        ...state,
        documents: state.documents.map((d) => {
          if (d.id === action.documentId) {
            const linkedDocuments = action.linkedDocuments || [];
            return {
              ...d,
              subRows: linkedDocuments.filter(
                (ld) => ld.id !== action.documentId
              ),
            };
          }

          return d;
        }),
      };
    case actions.repository.SET_REPOSITORY_READY:
      return {
        ...state,
        ready: action.ready,
      };
    case actions.repository.PRESERVE_SELECTED_DOCUMENTS:
      return {
        ...state,
        bulkUpdateProgress: 0,
      };
    case actions.repository.SET_BULK_UPDATE_PROGRESS:
      return {
        ...state,
        bulkUpdateProgress: action.bulkUpdateProgress,
      };
    case actions.repository.SET_SELECTED_FOLDER:
      return {
        ...state,
        selectedFolder: action.folder,
      };
    case actions.repository.UPDATE_SELECTED_FOLDER:
      return {
        ...state,
        selectedFolder: updateResource(
          state.selectedFolder,
          action.payload.propertyName,
          action.payload.value,
          action.payload.multiValueIndex
        ),
      };
    case actions.repository.UPDATE_SELECTED_FOLDER_MULTI_ATTRIBUTE:
      return {
        ...state,
        selectedFolder: {
          ...state.selectedFolder,
          ...action.payload.multiValueIndexes.reduce((acc, cur) => {
            return updateResourceMultiAttribute(
              acc,
              action.payload.propertyNames,
              null,
              cur
            );
          }, state.selectedFolder),
        },
      };
    case actions.repository.CLOSE_FOLDER:
      return {
        ...state,
        selectedFolder: null,
        highlightedDocs: [],
      };
    case actions.repository.COPY_TO_CLIPBOARD:
      return {
        ...state,
        clipboard: {
          documents: [...state.selectedDocuments],
          mode: action.mode,
        },
      };
    case actions.repository.HIGHLIGHT_DOCS:
      return {
        ...state,
        highlightedDocs: action.documentIds,
      };
    case actions.repository.CLEAR_CLIPBOARD:
      return {
        ...state,
        clipboard: {
          documents: [],
          mode: '',
        },
      };
    case actions.document.CLOSE_DOCUMENT:
      return {
        ...state,
        validationErrors: [],
      };
    case actions.repository.SET_THUMBNAIL_LOADING:
      return {
        ...state,
        thumbnailLoading: action.loading,
      };
    case actions.repository.SET_THUMBNAIL:
      return {
        ...state,
        thumbnail: action.thumbnail,
      };
    case actions.repository.HANDLE_RESOURCE_UPDATE_EVENT:
      return {
        ...state,
        selectedDocuments: state.selectedDocuments.map((doc) => {
          if (doc.id === action.payload.resourceId) {
            return {
              ...doc,
              ...(action.payload.data || {}),
            };
          }

          return doc;
        }),
      };
    case actions.document.SET_DOWNLOAD_PROGRESS:
      return {
        ...state,
        loadingProgress: action.payload.downloadProgress,
      };
    case actions.repository.SET_SEARCH_PROPERTIES:
      return {
        ...state,
        searchProperties: [
          ...action.payload.searchProperties,
          ...initialState.searchProperties,
        ],
      };
    case actions.repository.SET_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: {
          ...state.searchOptions,
          ...action.payload.searchOptions,
        },
      };
    case actions.repository.SET_FOLDER_TREE_STATE:
      return {
        ...state,
        folderTreeState: {
          folderIdentifier: action.payload.folderIdentifier,
          structureData: action.payload.initialTreeState,
        },
      };
    case actions.repository.UPDATE_FOLDER_TREE_STATE_OPTION:
      return {
        ...state,
        folderTreeState: {
          ...state.folderTreeState,
          structureData: _.mapValues(
            state.folderTreeState?.structureData,
            (lvl) => ({
              ...lvl,
              options: lvl.options.map((option) => ({
                ...option,
                collapsed:
                  action.payload.treeLevelOptionIdentifier === option.value
                    ? !option.collapsed
                    : option.collapsed,
              })),
            })
          ),
        },
      };
    case actions.repository.UPDATE_FOLDER_TREE_STATE_OPTIONS:
      return {
        ...state,
        folderTreeState: {
          ...state.folderTreeState,
          structureData: _.mapValues(
            state.folderTreeState?.structureData,
            (lvl) => ({
              ...lvl,
              options: lvl.options.map((option) => {
                return {
                  ...option,
                  collapsed: action.payload.preset,
                };
              }),
            })
          ),
        },
      };
    default:
      return state;
  }
};

export default repository;
