import * as _ from 'lodash-es';
import { createSelector } from 'reselect';
import { uploadErrorType } from '../Constants/uploadErrorType';

export const getUploadOptions = (state) => state.upload.options;
export const getUploadFiles = (state) => state.upload.files;

export const getUploadOptionsDisabled = createSelector(
  getUploadFiles,
  (files) => files.some((f) => f.parentFileName != null)
);

export const getExistingDocuments = (state) => state.upload.existingDocuments;
export const getDuplicates = (state) => state.upload.duplicates;

export const getUploadErrors = createSelector(
  [getExistingDocuments, getDuplicates, getUploadFiles],
  (existingDocuments, duplicatesMap, files) => {
    const filesMap = _.keyBy(files, (f) => `${f.name}_${f.path || ''}`);

    if (existingDocuments == null) {
      return [];
    }

    const existingDocs = existingDocuments.map((ed) => ({
      ...ed,
      type: filesMap[`${ed.name}_${ed.path || ''}`]?.reference
        ? uploadErrorType.REFERENCE
        : ed.type,
    }));

    const duplicateErrors = Object.entries(duplicatesMap).map(
      ([hash, files]) => {
        return {
          type: uploadErrorType.FILE_CONTENT_DUPLICATE,
          hash,
          files,
        };
      }
    );

    return { existingDocuments: existingDocs, duplicateErrors };
  }
);

export const getUploadFilesWithErrors = createSelector(
  [getExistingDocuments, getDuplicates, getUploadFiles],
  (existingDocuments, duplicatesMap, files) => {
    const uploadFilesWithErrors = files.map((f) => {
      const existingDocumentsForFile = existingDocuments.filter(
        (ed) => ed.name === f.name && ed.path === f.path
      );
      const duplicatesForFile = Object.entries(duplicatesMap)
        .filter(([hash, files]) => {
          return files.some((file) => {
            return file.fileIdentifier === f.fileIdentifier;
          });
        })
        .map(([hash, files]) => {
          return {
            type: uploadErrorType.FILE_CONTENT_DUPLICATE,
            hash,
            files,
          };
        });

      return {
        ...f,
        errors: [...existingDocumentsForFile, ...duplicatesForFile],
      };
    });

    return uploadFilesWithErrors;
  }
);
