/* eslint-disable func-names */
import {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
} from 'cosmos-config/utils';
import * as _ from 'lodash-es';
import {
  call,
  take,
  cancel,
  fork,
  getContext,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import * as actions from '../../Actions/types';
import businessProcessApi from '../../Api/businessProcess';
import {
  getActiveFolder,
  getSelectedDocuments,
} from '../../Selectors/repository';
import callApi from '../Effects/callApi';
import { notify } from '../../Actions/ui';
import { validateResources } from 'cosmos-core';
import {
  fetchBusinessProcessDefinitions,
  fetchBusinessProcesses,
  fetchUserBusinessProcessTasks,
  setBusinessProcessDefinitions,
  setBusinessProcesses,
  setBusinessProcessLoading,
  setBusinessProcessTasks,
  setBusinessProcessValidationErrors,
} from '../../Actions/businessProcess';
import { parseResourceId } from '../../Utils';
import { openDocument } from '../../Actions/document';
import projectApi from '../../Api/project';
import complete from '../Effects/complete';

const buildProperties = (groups, taskDefinitionKey) => {
  const filteredGroups = _.chain(groups).filter(
    (g) =>
      !g.group ||
      taskDefinitionKey == null ||
      g.dedicated == null ||
      g.dedicated === taskDefinitionKey
  );
  const flatGroups = filteredGroups.flatMap((p) =>
    p.group ? p.buildChildren() : [p]
  );
  const uniqGroups = flatGroups.uniqBy('name');
  return uniqGroups.value();
};

function* getBusinessProcessRepositoryProperties(
  processDefinitionKey,
  folderType
) {
  const projectService = yield getContext('projectService');

  const businessProcesses = yield call(projectService.getBusinessProcesses);
  const businessProcess = businessProcesses.find(
    (bp) => bp.processDefinitionKey === processDefinitionKey
  );

  const resourcePropertiesNames = _.compact(
    businessProcess?.resourceProperties
  );

  if (resourcePropertiesNames.length > 0) {
    const properties = yield call(projectService.getProperties);
    const filteredProperties = _.filter(properties, (p) =>
      resourcePropertiesNames.includes(p.name)
    );
    return filteredProperties;
  }

  if (folderType != null) {
    return yield call(projectService.getFolderProperties);
  }

  return [];
}

function* validateData(data, properties) {
  let validationErrors = yield call(validateResources, [data], properties);
  let errors = _.flatMap(validationErrors, 'errors');

  if (errors.length > 0) {
    yield put(setBusinessProcessValidationErrors(errors));
    throw new Error(
      'There were validation errors in task attributes. Check task attributes, please and resubmit the form.'
    );
  }

  yield put(setBusinessProcessValidationErrors([]));
}

function* prepareVariables(processDefinitionKey, taskDefinitionKey, variables) {
  const projectService = yield getContext('projectService');
  const businessProcessProperties = yield call(
    projectService.getBusinessProcessProperties,
    processDefinitionKey
  );

  const properties = buildProperties(
    businessProcessProperties,
    taskDefinitionKey
  );

  yield validateData(variables, properties);

  const correctedVariables = resourceCorrectionForGateway(
    variables,
    properties
  );

  const taskPropertyNames = _.chain(properties)
    .filter((p) => !p.isHidden(variables) && !p.disabled)
    .map('name')
    .value();
  const taskData = _.pick(correctedVariables, taskPropertyNames);

  if (!_.has(variables, 'resource')) {
    return taskData;
  }

  const resourceProperties = yield call(
    getBusinessProcessRepositoryProperties,
    processDefinitionKey,
    correctedVariables.folderType
  );

  if (taskDefinitionKey === 'start' && correctedVariables.resourceType === 1) {
    yield validateData(variables.resource, resourceProperties);
  }

  return {
    ...taskData,
    resource: resourceCorrectionForGateway(
      variables.resource,
      resourceProperties
    ),
  };
}

function* makeVariablesCorrection(items) {
  const processDefinitionKey = _.get(items, '[0].processDefinitionKey');
  const projectService = yield getContext('projectService');
  const businessProcessProperties =
    projectService.getBusinessProcessProperties(processDefinitionKey);

  const properties = buildProperties(businessProcessProperties);

  let correctedItems = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const resourceVariables = item.variables?.resource || {};
    const resourceProperties = yield call(
      getBusinessProcessRepositoryProperties,
      processDefinitionKey,
      item.variables?.folderType
    );
    const correctedResource = resourceCorrectionOfGateway(
      resourceVariables,
      resourceProperties
    );

    correctedItems = [
      ...correctedItems,
      {
        ...item,
        variables: {
          ...resourceCorrectionOfGateway(item.variables || {}, properties),
          resource: correctedResource,
        },
      },
    ];
  }

  return correctedItems;
}

function* taskSaga() {
  let lastTaskId = null;
  let openTaskFork = null;

  while (true) {
    const { type, payload } = yield take([
      actions.businessProcess.OPEN_BUSINESS_PROCESS_TASK,
      actions.businessProcess.SET_BUSINESS_PROCESS_TASKS,
    ]);

    let tasks = [];

    if (type === actions.businessProcess.OPEN_BUSINESS_PROCESS_TASK) {
      lastTaskId = payload.taskId;
      tasks = yield select((state) => state.businessProcess.tasks);
    } else if (type === actions.businessProcess.SET_BUSINESS_PROCESS_TASKS) {
      tasks = payload.tasks;
    }

    const task = tasks.find((t) => t.id === lastTaskId);

    if (task != null) {
      if (openTaskFork) {
        yield cancel(openTaskFork);
      }

      openTaskFork = yield fork(function* () {
        const { resourceId } = task.variables;

        if (resourceId != null) {
          const { identifier } = parseResourceId(resourceId);
          yield put(openDocument(identifier));
        }
      });
    }
  }
}

function* processSaga() {
  let lastProcessId = null;
  let openProcessFork = null;

  while (true) {
    const { type, payload } = yield take([
      actions.businessProcess.OPEN_BUSINESS_PROCESS,
      actions.businessProcess.SET_BUSINESS_PROCESSES,
    ]);

    let processes = [];

    if (type === actions.businessProcess.OPEN_BUSINESS_PROCESS) {
      lastProcessId = payload.processId;
      processes = yield select((state) => state.businessProcess.processes);

      if (processes.length === 0) {
        yield put(fetchBusinessProcesses());
      }
    } else if (type === actions.businessProcess.SET_BUSINESS_PROCESSES) {
      processes = payload.processes;
    }

    const process = processes.find((p) => p.id === lastProcessId);

    if (process != null) {
      if (openProcessFork) {
        yield cancel(openProcessFork);
      }

      openProcessFork = yield fork(function* () {
        const { resourceId } = process.variables;

        if (resourceId != null) {
          const { identifier } = parseResourceId(resourceId);
          yield put(openDocument(identifier));
        }
      });
    }
  }
}

function* businessProcessSaga(projectCode) {
  yield takeEvery(
    actions.businessProcess.FETCH_BUSINESS_PROCESS_DEFINITIONS,
    function* () {
      try {
        yield put(setBusinessProcessLoading(true));

        const processDefinitions = yield callApi(
          businessProcessApi.getProcessDefinitions
        );
        yield put(setBusinessProcessDefinitions(processDefinitions));
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setBusinessProcessLoading(false));
      }
    }
  );

  yield put(fetchBusinessProcessDefinitions());

  yield takeEvery(
    actions.businessProcess.FETCH_USER_BUSINESS_PROCESS_TASKS,
    function* () {
      try {
        yield put(setBusinessProcessLoading(true));

        const tasks = yield callApi(projectApi.getUserTasks, projectCode);
        const correctedTasks = yield call(makeVariablesCorrection, tasks);

        yield put(setBusinessProcessTasks(correctedTasks));
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setBusinessProcessLoading(false));
      }
    }
  );

  yield takeEvery(
    actions.businessProcess.FETCH_BUSINESS_PROCESS_TASK_HISTORY,
    function* (action) {
      try {
        yield put(setBusinessProcessLoading(true));
        const historyTasks = yield callApi(
          projectApi.getUserTaskHistory,
          projectCode
        );

        yield complete(action, historyTasks);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setBusinessProcessLoading(false));
      }
    }
  );

  yield takeEvery(
    actions.businessProcess.FETCH_BUSINESS_PROCESSES,
    function* (action) {
      try {
        yield put(setBusinessProcessLoading(true));

        const processes = yield callApi(
          projectApi.getUserProcesses,
          projectCode
        );

        const correctedProcesses = yield call(
          makeVariablesCorrection,
          processes
        );

        yield put(setBusinessProcesses(correctedProcesses));

        yield complete(action, correctedProcesses);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setBusinessProcessLoading(false));
      }
    }
  );

  yield takeLeading(
    actions.businessProcess.START_BUSINESS_PROCESS,
    function* (action) {
      const { processDefinitionKey, variables, options } = action.payload;
      const { useFolder } = options;

      let resourceIds = [];

      if (useFolder) {
        const selectedFolder = yield select(getActiveFolder);
        resourceIds = _.compact([selectedFolder?.id]);
      } else {
        const selectedDocuments = yield select(getSelectedDocuments);
        resourceIds = _.compact(selectedDocuments.map((d) => d.id));
      }

      try {
        yield put(setBusinessProcessLoading(true));

        const correctedVariables = yield call(
          prepareVariables,
          processDefinitionKey,
          'start',
          variables
        );

        const userTasks = yield callApi(
          projectApi.createBusinessProcess,
          projectCode,
          {
            processDefinitionKey,
            resourceIds,
            variables: correctedVariables,
          }
        );

        yield complete(action, userTasks);
      } catch (err) {
        yield put(notify(err.message, 'user-error'));
        console.error(err);
      } finally {
        yield put(setBusinessProcessLoading(false));
      }
    }
  );

  yield takeLeading(
    actions.businessProcess.CANCEL_BUSINESS_PROCESS,
    function* (action) {
      const { processId } = action.payload;

      try {
        yield put(setBusinessProcessLoading(true));

        yield callApi(businessProcessApi.cancelProcess, processId);

        yield complete(action);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setBusinessProcessLoading(false));
      }
    }
  );

  const callApiWithLoader = (saga, ...args) =>
    call(function* () {
      try {
        yield put(setBusinessProcessLoading(true));
        const result = yield callApi(saga, ...args);

        return result;
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setBusinessProcessLoading(false));
      }
    });

  yield takeLeading(
    actions.businessProcess.UPDATE_BUSINESS_PROCESS,
    function* (action) {
      const { processDefinitionKey, processId, variables } = action.payload;

      try {
        const correctedVariables = yield call(
          prepareVariables,
          processDefinitionKey,
          'start',
          variables
        );

        yield callApiWithLoader(businessProcessApi.updateProcess, processId, {
          variables: correctedVariables,
        });

        yield complete(action);
      } catch (err) {
        console.error(err);
        yield put(notify(err.message, 'user-error'));
      }
    }
  );

  yield takeLeading(
    actions.businessProcess.SUBMIT_BUSINESS_PROCESS_TASK,
    function* (action) {
      const { taskId, variables, processDefinitionKey, taskDefinitionKey } =
        action.payload;

      try {
        const correctedVariables = yield call(
          prepareVariables,
          processDefinitionKey,
          taskDefinitionKey,
          variables
        );

        yield put(setBusinessProcessLoading(true));

        console.log(correctedVariables, variables);

        yield callApi(businessProcessApi.submitTask, taskId, {
          variables: correctedVariables,
        });

        yield put(notify('Submission was successful.', 'success'));

        yield put(fetchUserBusinessProcessTasks());

        yield complete(action);
      } catch (err) {
        console.error(err);
      } finally {
        yield put(setBusinessProcessLoading(false));
      }
    }
  );

  yield fork(taskSaga);
  yield fork(processSaga);
}

export default businessProcessSaga;
