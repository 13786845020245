/* eslint-disable func-names */
import { eventChannel } from 'redux-saga';
import {
  take,
  put,
  call,
  takeEvery,
  fork,
  takeLatest,
  retry,
  select,
  delay,
} from 'redux-saga/effects';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { fetchUserBusinessProcessTasks } from '../Actions/businessProcess';
import { handleResourceUpdateEvent } from '../Actions/repository';
import {
  WS_READY,
  WS_RELOAD,
  WS_SUBSCRIBE_COMMENTS,
  WS_SUBSCRIBE_RESOURCE_EVENTS,
} from '../Actions/types/websocket';
import { notify } from '../Actions/ui';
import { receivedComment, reloadWebsocket } from '../Actions/websocket';
import { getDefaultPositionId } from '../Selectors/userdata';

const BASE_URL = import.meta.env.VITE_APP_GATEWAY_URL;

function* createStompClient() {
  const socket = new SockJS(`${BASE_URL}websocket-channel`);
  const stompClient = Stomp.over(socket);

  const authToken = localStorage.getItem('authToken');
  const defaultPositionId = yield select(getDefaultPositionId);

  const ready = eventChannel((emitter) => {
    stompClient.connect(
      { Authorization: authToken, userId: defaultPositionId },
      () => {
        emitter({ type: WS_READY });
      },
      () => {
        emitter(new Error('Connection had been lost'));
      }
    );

    return () => {
      stompClient.disconnect();
    };
  });

  yield take(ready);
  return stompClient;
}

const createSockedChannel = (client, path) => {
  return eventChannel((emitter) => {
    const subscription = client.subscribe(path, (frame) => {
      const payload = JSON.parse(frame.body);
      emitter({ type: 'WS_DATA_RECEIVED', payload });
    });

    return () => {
      subscription.unsubscribe();
    };
  });
};

export default function* websocketSaga() {
  yield takeLatest(WS_RELOAD, function* () {
    try {
      const stompClient = yield retry(3, 30000, createStompClient);

      const defaultPositionId = yield select(getDefaultPositionId);

      const userEventChannel = yield call(
        createSockedChannel,
        stompClient,
        // `/user/${defaultPositionId}/queue/events`
        `/queue/user/${defaultPositionId}/events`
      );

      yield takeEvery(userEventChannel, function* ({ payload }) {
        switch (payload.type) {
          case 'bp_assignment':
            yield delay(3000);
            yield put(fetchUserBusinessProcessTasks());
            break;
          case 'comment_mention':
          default:
            // yield put(notify(`Notification: ${JSON.stringify(data)}`, 'info'));
            break;
        }
      });

      yield fork(function* () {
        let commentsChannel;
        while (true) {
          const { resourceId } = yield take(WS_SUBSCRIBE_COMMENTS);

          if (commentsChannel != null) {
            commentsChannel.close();
          }

          commentsChannel = yield call(
            createSockedChannel,
            stompClient,
            `/queue/comments/${resourceId}`
          );

          yield takeEvery(commentsChannel, function* ({ payload }) {
            yield put(receivedComment(payload));
          });
        }
      });

      yield fork(function* () {
        let commentsChannel;
        while (true) {
          const { resourceId } = yield take(WS_SUBSCRIBE_RESOURCE_EVENTS);

          if (commentsChannel != null) {
            commentsChannel.close();
          }

          commentsChannel = yield call(
            createSockedChannel,
            stompClient,
            `/queue/resource/${resourceId}/events`
          );

          yield takeEvery(commentsChannel, function* ({ payload }) {
            const { type, resourceId } = payload;

            if (['resource_locked', 'resource_unlocked'].includes(type)) {
              const lockowner = type === 'resource_locked' ? '-2' : null;
              yield put(
                handleResourceUpdateEvent(resourceId, {
                  lockowner,
                })
              );

              if (type === 'resource_unlocked') {
                yield put(
                  notify('New document version has been created.', 'success')
                );
              } else {
                yield put(notify('The document is in the edit mode.', 'info'));
              }
            }
          });
        }
      });
    } catch (err) {
      console.error(err);
      yield put(
        notify(
          `It was not possible to establish websocket connections. ${err.message}`,
          'system-error'
        )
      );
    }
  });

  yield put(reloadWebsocket());
}
