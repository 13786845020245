/* eslint-disable func-names */
import { eventChannel } from 'redux-saga';
import { take, put, takeLeading, delay, race, call } from 'redux-saga/effects';
import { LOGIN_EXTERNAL_ADFS } from '../../Actions/types/userdata';
import { loginADFSError, loginADFSSuccess } from '../../Actions/userdata';
import { generateUrlParams } from '../../Utils';
import { uuidv4 } from 'cosmos-config/utils';

const hostUrl = 'https://rweresdms.b2clogin.com';
const tenant = '7db2e317-ba84-4d8b-b143-be91128efcb6/b2c_1_signupandsignin';
const clientId = '8e7781e3-08df-443c-a9be-a53cfdf5d5cc';
const redirectUri = import.meta.env.VITE_APP_ADAL_REDIRECT_URI;

const generateAuthorizeUrl = () => {
  const params = {
    client_id: clientId,
    response_type: 'id_token',
    redirect_uri: redirectUri,
    scope: 'openid email profile',
    nonce: uuidv4(),
  };

  return `${hostUrl}/${tenant}/oauth2/v2.0/authorize?${generateUrlParams(
    params
  )}`;
};

export default function* externalAdfsSaga() {
  const tokenChannel = yield eventChannel((emitter) => {
    const messageListener = (event) => {
      const { data } = event;
      if (data != null && data.accessToken != null) {
        emitter({ type: 'ADFS_TOKEN_RECEIVED', token: data.accessToken });
      }
    };

    window.addEventListener('message', messageListener, false);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  });

  yield takeLeading(LOGIN_EXTERNAL_ADFS, function* () {
    const windowReference = window.open(
      generateAuthorizeUrl(),
      'RWE ADFS',
      'toolbar=no, menubar=no, width=500, height=700, top=100, left=100'
    );

    function* checkWindowClose() {
      while (true) {
        if (windowReference.closed) {
          break;
        }

        yield delay(1000);
      }
    }

    try {
      const [action] = yield race([take(tokenChannel), call(checkWindowClose)]);
      const idToken = action?.token;

      localStorage.setItem('authToken', `Bearer ${idToken}`);
      localStorage.setItem('domain', 'ExternalUsers');
      yield put(loginADFSSuccess());
    } catch (err) {
      console.error(err);
      yield put(loginADFSError());
    } finally {
      windowReference.close();
    }
  });
}
