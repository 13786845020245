import mfe from 'mime-file-extension';
import JSZip from 'jszip';
import * as _ from 'lodash-es';
import { getFileIdentifier } from 'cosmos-core';

export const readAsBinary = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      // var arrayBuffer = reader.result,
      //   array = new Uint8Array(arrayBuffer),
      //   binaryString = String.fromCharCode.apply(null, array);

      // resolve(binaryString);

      const base64String = reader.result.replace(
        // eslint-disable-next-line no-useless-escape
        /^data:([\w]+?\/(([\w][\.|\-]?)+?));base64,/,
        ''
      );

      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const binarytoBlob = (data, contentType) => {
  const byteString = atob(data);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], {
    type: contentType,
  });
};

/**
 * It takes a zip file, unzips it, and returns an array of files
 * @param zipFile - The zip file that you want to unzip.
 * @returns An array of files.
 */
export const unzipFile = (zipFile) => {
  return JSZip.loadAsync(zipFile).then((archive) => {
    const files = Object.values(archive.files);

    const filesPromises = files
      .filter((f) => !f.dir)
      .map((f) =>
        f.async('blob').then((blob) => {
          try {
            const extension = _.chain(f.name).split('.').last().toLowerCase().value();
            const type = mfe.getMimeType(extension);
            const filename = f.name.split('/').pop();
            const file = new File([blob], filename, {
              type,
            });
            file.path = generateProperFilePath(f.name);

            return Promise.resolve(file);
          } catch (err) {
            console.error(err);
          }
        })
      );

    return Promise.all(filesPromises);
  });
};

export const archiveContentTypes = [
  'application/zip',
  'application/x-zip-compressed',
];

export const flatMapArchives = async (files) => {
  let results = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    if (file != null) {
      if (archiveContentTypes.includes(file.type)) {
        try {
          const zipFiles = await unzipFile(file);
          const recursiveResults = await flatMapArchives(zipFiles);
          results = [...results, ...recursiveResults];
        } catch (err) {
          console.error(err);
          results = [...results, file];
        }
      } else {
        results = [...results, file];
      }
    }
  }

  return results;
};

export const getFileSize = (size) => {
  if (!size || isNaN(size)) return { size: 0, prettySize: '0 Bytes' };

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const parsedSize = parseInt(Math.floor(Math.log(size) / Math.log(1024)), 10);
  const prettySize = (size / 1024 ** parsedSize).toFixed(2);

  return {
    size: +prettySize,
    prettySize: `${prettySize} ${sizes[parsedSize]}`,
  };
};

export const trimFileExtension = (fn) => _.chain(fn).split('.').dropRight().join('.');

export const getFileExtension = (filename) => {
  if (typeof filename !== 'string') return '';
  return filename.split('.').pop();
};

export const createLinkToDownload = (objectUrl, fileName) => {
  const link = document.createElement('a');
  link.href = objectUrl;
  link.download = fileName;
  link.dispatchEvent(new MouseEvent('click'));
};

export const generateProperFilePath = (relativePath) => {
  const parts = String(relativePath).split('/');
  return _.chain(parts).drop().dropRight().join('/').value();
};

export const parseFilesToResources = (documentFiles) => {
  return _.chain(documentFiles)
    .map((f) => ({
      filename: f.name,
      displayname: _.chain(f.name).split('.').dropRight().join('').value(),
      _path: f.path || '',
    }))
    .orderBy('_path')
    .value();
};

export const isContentTypeViewable = (contentType) => {
  return ![
    'application/x-zip-compressed',
    'application/zip',
    'audio/mpeg',
    'image/x-dwg',
  ].includes(contentType);
};

export const generateUniqueFileLabel = (filePath, fileName) => {
  if (!filePath || !filePath.length) {
    return fileName || '';
  }

  if (!fileName || !fileName.length) {
    return '';
  }

  // if filePath exists, check if it ends with a slash
  const lastChar = filePath[filePath.length - 1];
  if (lastChar !== '/') {
    filePath += '/';
  }

  return filePath + fileName;
};

export const blobToFileInfo = (b, idx) => ({
  name: b.name,
  path: b.path || '',
  size: b.size,
  confidential: !!b.confidential,
  order: idx,
  placeholder: !!b.placeholder,
  type: b.type,
  fileIdentifier: getFileIdentifier(b),
});
