import { generateFolderId } from '../Utils';
import Api from './conf/Api';
import GatewayApi from './conf/GatewayApi';
import { select } from 'cosmos-config/nql';

const api = Api.build();
const gatewayApi = GatewayApi.build();

const ROOT_FOLDER_ID = import.meta.env.VITE_APP_ROOT_FOLDER_ID;

const getUserdata = (principalId) => {
  return api
    .get(`/usermanagement/principal/${principalId}/userinfo`)
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const getUserRoles = (docArea) => {
  const query = select([
    'roleNames=pidResolve(groupResolve(%currentUserPrincipalId, true), 0)',
  ])
    .paging({ number: 1, size: 1 })
    .toString();

  const folderId = generateFolderId(docArea, ROOT_FOLDER_ID);
  return api
    .get(`/repository/folder/${folderId}/search`, {
      params: { query },
    })
    .then((response) => {
      const { properties } = response.data.items[0] || { properties: [] };
      if (properties[0] != null && properties[0].value != null) {
        const {
          value: { list },
        } = properties[0];
        return Promise.resolve(list);
      }

      return Promise.resolve([]);
    });
  // return requestUsers(query);
};

const logout = () => {
  return api.get('/logout').then((response) => {
    return Promise.resolve(response.data);
  });
};

const login = (authToken, domainName) => {
  return gatewayApi
    .get('/login', {
      headers: {
        Authorization: authToken,
      },
      params: {
        domain: domainName,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const postDelegate = (delegate) => {
  return gatewayApi.post('/user/delegate', delegate).then((response) => {
    return Promise.resolve(response.data);
  });
};

const getDelegateStatus = () => {
  return gatewayApi.get('/user/delegate-status').then((response) => {
    return Promise.resolve(response.data);
  });
};

const deactivateDelegates = () => {
  return gatewayApi.put('/user/deactivate-delegates').then((response) => {
    return Promise.resolve(response.data);
  });
};

const getUserTasks = () => {
  return gatewayApi.get('/user/tasks').then((response) => {
    return Promise.resolve(response.data);
  });
};

const getNotificationPreferences = () => {
  return gatewayApi.get('/user/notification-preferences').then((response) => {
    return Promise.resolve(response.data);
  });
};

const toggleNotificationPreference = (name, value) => {
  return gatewayApi
    .put(`/user/notification-preference/${name}`, {
      active: value,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

const toggleAllNotificationPreferences = (value) => {
  return gatewayApi
    .put(`/user/notification-preferences/toggle-all`, {
      active: !!value,
    })
    .then((response) => {
      return Promise.resolve(response.data);
    });
};

export default {
  getUserdata,
  login,
  getUserRoles,
  logout,
  postDelegate,
  getDelegateStatus,
  deactivateDelegates,
  getUserTasks,
  getNotificationPreferences,
  toggleNotificationPreference,
  toggleAllNotificationPreferences,
};
