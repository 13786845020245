import { put } from 'redux-saga/effects';
import { setRepositoryLoading } from '../../Actions/repository';
import * as _ from 'lodash-es';
import complete from '../Effects/complete';
import repository from '../../Api/repository';
import reportProgress from '../Effects/reportProgress';
import { setDownloadProgress } from '../../Actions/document';
import { notify } from '../../Actions/ui';

/**
 * Export document contents as zip.
 *
 * @param {Document[]} documents - Documents to be exported.
 * @param {object} action - Redux action object.
 * @param {function} action.callback - Callback when the operation is done.
 */
function* downloadDocumentsSaga(documents, action) {
  yield put(
    setRepositoryLoading(true, 'Preparing to export documents as zip archive')
  );

  try {
    const { data, contentType, fileName } = yield reportProgress(
      repository.downloadDocuments,
      _.map(documents, 'id')
    );

    const blob = new Blob([data], { type: contentType });

    yield complete(action, {
      contentUrl: URL.createObjectURL(blob),
      fileName,
    });
  } catch (err) {
    yield put(notify(err.message, 'error'));
  }

  yield put(setRepositoryLoading(false));
  yield put(setDownloadProgress(0));
}

export default downloadDocumentsSaga;
