const importIssueType = {
  REQUIRED: 'REQUIRED',
  FILE_MISSING_FILESET: 'FILE_MISSING_FILESET',
  FILE_MISSING_TEMPLATE: 'FILE_MISSING_TEMPLATE',
  FILE_CONTENT_EXISTS: 'FILE_CONTENT_EXISTS',
  FILE_CONTENT_DUPLICATE: 'FILE_CONTENT_DUPLICATE',
  FILE_EMPTY: 'FILE_EMPTY',
  FILE_OVERSIZED: 'FILE_OVERSIZED',
  COLUMN_UNMATCHED: 'COLUMN_UNMATCHED',
  PARENT_UNMATCHED: 'PARENT_UNMATCHED',
  DATA_TYPE_MALFORMED: 'DATA_TYPE_MALFORMED',
  MISSING: 'MISSING',
  DEFAULT: 'DEFAULT',
};

export const importIssueTypeSeverity = {
  REQUIRED: 'error',
  FILE_MISSING_FILESET: 'warning',
  FILE_MISSING_TEMPLATE: 'warning',
  FILE_CONTENT_EXISTS: 'error',
  FILE_CONTENT_DUPLICATE: 'error',
  FILE_EMPTY: 'warning',
  FILE_OVERSIZED: 'warning',
  COLUMN_UNMATCHED: 'error',
  PARENT_UNMATCHED: 'error',
  DATA_TYPE_MALFORMED: 'error',
  MISSING: 'error',
  DEFAULT: 'error',
};

// TODO: i18n -> could use i18n instance from cosmos-core
// Normally, those issue messages should be localized as well
// but due to their technical nature, they are left as is for now
export const importIssueTypeText = {
  [importIssueType.REQUIRED]:
    'Property {propertyLabel} is required but cell is empty.',
  [importIssueType.FILE_MISSING_FILESET]:
    'File "{fileName}" is listed in the Excel template but could not be found. Will be skipped',
  [importIssueType.FILE_MISSING_TEMPLATE]:
    'File "{uniqueFileLabel}" is not listed in the Excel template. Will be skipped.',
  [importIssueType.FILE_CONTENT_EXISTS]:
    'File "{filePath}/{fileName}" already exists as "{value}".',
  [importIssueType.FILE_CONTENT_DUPLICATE]:
    'File "{filePath}/{fileName}" has the same content as other files.',
  [importIssueType.FILE_EMPTY]:
    'File "{filePath}/{fileName}" is empty. It was removed from the fileset.',
  [importIssueType.FILE_OVERSIZED]:
    'File "{filePath}/{fileName}" is too large ({fileSize} exceeds the limit of {maxSize}). It was removed from the fileset.',
  [importIssueType.COLUMN_UNMATCHED]:
    'Column "{columnName}" not matched with project properties.',
  [importIssueType.PARENT_UNMATCHED]:
    'Cannot find parent folder with value "{value}".',
  [importIssueType.DATA_TYPE_MALFORMED]:
    'Cannot parse value(s) for {propertyLabel}.',
  [importIssueType.MISSING]: 'Value "{value}" is missing in {propertyLabel}.',
  [importIssueType.DEFAULT]: 'Some unknown error occurred with {value}.',
};

export const printIssueText = (
  issue,
  issuePropertyRenderingHandler = (t) => t
) => {
  const issueText =
    importIssueTypeText[issue.type] || importIssueTypeText.DEFAULT;
  return issueText.replace(/{(.*?)}/g, (match, p1) => {
    return issuePropertyRenderingHandler(issue[p1]);
  });
};

export default importIssueType;
