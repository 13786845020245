/* eslint-disable no-bitwise */
import * as _ from 'lodash-es';
import { DateTime } from 'luxon';
import { group } from 'cosmos-config/generator';

/**
 * Generic utilities and helper functions.
 * @module utils
 *
 */

/**
 * If the values of the columnId are strings, then sort them alphabetically.
 * @function
 * @param columnId - The column id of the column being sorted.
 */
export const stringSortType = (
  { values: avalues },
  { values: bvalues },
  columnId
) => String(avalues[columnId]).localeCompare(bvalues[columnId]);

/**
 * It takes a timestamp and returns a relative time string.
 * @function
 * @param timestamp - The timestamp to convert.
 * @returns A function that takes a timestamp and returns a string.
 */
export const timestampToRelative = (timestamp) => {
  return timestamp != null && timestamp !== ''
    ? DateTime.fromMillis(timestamp).toRelative()
    : '';
};

/**
 * It takes a timestamp and returns a formatted date string
 * @function
 * @param timestamp - The timestamp to convert.
 * @returns A string with the date and time in the format: "dd.MM.yyyy HH:mm"
 */
export const timestampToDate = (timestamp) => {
  return timestamp != null && typeof timestamp === 'number'
    ? DateTime.fromMillis(timestamp)
        .setLocale('de-DE')
        .toLocaleString(DateTime.DATETIME_SHORT)
    : null;
};

/**
 * It takes a string, converts it to an array of characters, then converts each character to its ASCII
 * code, then adds each ASCII code to a running total, then converts the running total to a 32-bit
 * integer, then converts the 32-bit integer to a base 36 string.
 * @function
 * @param str - The string to hash.
 * @returns A string of 36 characters.
 */
export const simpleHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash;
  }
  return new Uint32Array([hash])[0].toString(36);
};

/**
 * Parse resource id.
 * @function
 * @param id - The id of the resource
 * @returns An object with the properties valid, docarea, identifier, and resourceType.
 */
export const parseResourceId = (id) => {
  const parts = String(id).split('$');

  if (parts.length < 3) {
    return {
      valid: false,
      docArea: null,
      identifier: null,
      resourceType: null,
    };
  }

  return {
    valid: true,
    docarea: parts[0],
    identifier: parseInt(parts[2], 10),
    resourceType: parseInt(parts[3], 10),
  };
};

/**
 * It takes a resource id and returns the folder identifier
 * @function
 * @param id - The resource id of the folder.
 * @returns The identifier of the folder.
 */
export const parseFolderIdentifier = (id) => {
  const { identifier, resourceType, valid } = parseResourceId(id);

  if (!valid) {
    throw new Error('Resource id is not valid!');
  }

  if (resourceType !== 1) {
    throw new Error('Resource id is not of type folder!');
  }

  return identifier;
};

/**
 * It generates a resource id.
 * @function
 * @param docarea - The document area of the resource.
 * @param identifier - The unique identifier for the resource.
 * @param resourcetype - The type of resource you want to create.
 */
const generateResourceId = (docarea, identifier, resourcetype) =>
  `${docarea}$NOTSET$${identifier}$${resourcetype}$NOTSET`;

/**
 * It generates a folder id based on the docarea and identifier.
 * @function
 * @param docarea - The document area.
 * @param identifier - The identifier of the folder.
 */
export const generateFolderId = (docarea, identifier) =>
  generateResourceId(docarea, identifier, 1);

/**
 * It takes a document area and an identifier and returns a document id.
 * @function
 * @param docarea - The document area.
 * @param identifier - The document identifier.
 */
export const generateDocumentId = (docarea, identifier) =>
  generateResourceId(docarea, identifier, 2);

/**
 * It generates a reference id for a document area and identifier.
 * @function
 * @param docarea - The document area.
 * @param identiier - The identifier of the resource.
 */
export const generateReferenceId = (docarea, identiier) =>
  generateResourceId(docarea, identiier, 3);

/**
 * It takes a document area and an identifier and returns a version id.
 * @function
 * @param docarea - The document area of the document.
 * @param identifier - The document identifier
 */
export const generateVersionId = (docarea, identifier) =>
  `${docarea}$6$NOTSET$104$${identifier}`;

/**
 * It takes a string and returns a string.
 * @function
 * @param identifier - The identifier of the resource.
 */
export const generateVersionResourceId = (docarea, identifier) =>
  `${docarea}$${identifier}$NOTSET$2$NOTSET`;

export const prepareGroupedProperties = (
  propertiesMap,
  label = 'Group Wrapper',
  name = 'group-wrapper'
) => {
  const groups = [
    ...propertiesMap.filter((p) => p.group),
    group(label, name).children(propertiesMap.filter((p) => !p.group)),
  ];

  return groups.map((g) => g.build());
};

/**
 * It takes an object and returns a string of URL parameters
 * @function
 * @param params - The object containing the parameters to be encoded.
 */
export const generateUrlParams = (params) => {
  return Object.entries(params)
    .filter(([, value]) => value != null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');
};

/**
 * It takes a string, splits it into words, and then joins them together with the Elasticsearch fuzzy
 * query syntax
 * @function
 * @param query - The query string to be sanitized.
 * @returns A string.
 */
export const fuzzyQuery = (query) => {
  if (_.isEmpty(query)) {
    return '';
  }

  const sanitized = String(query).toLowerCase().trim();

  return _.chain(sanitized)
    .split(' ')
    .map((part) => `${part}~0.7`)
    .join(' AND ')
    .concat(` OR (*${sanitized}*)`)
    .value();
};

export const uuidCode = (uuid, length = 8) => {
  const hexString = uuid.replace(/-/g, '');
  return _.take(btoa(parseInt(hexString, 16)), length)
    .join('')
    .toLowerCase();
};

/**
 * It returns an array of non-null values from the given value.
 * @function
 * @param v - The value to be compacted.
 */
export const compactValue = (v) => {
  if (Array.isArray(v)) {
    return v.filter((x) => x != null);
  }

  return v != null ? [v] : [];
};

/**
 * It creates a mailto link with the given parameters and opens the mail client
 * @function
 * @param config - The configuration object for the mailto link.
 */
export const openMailClient = ({
  email = '',
  cc = '',
  bcc = '',
  subject = '',
  body = '',
}) => {
  if (!email || !subject) {
    return;
  }

  let mailToLink = `mailto:${email}`;
  mailToLink += `?subject=${encodeURIComponent(subject)}`;

  if (cc) {
    mailToLink += `&cc=${encodeURIComponent(cc)}`;
  }

  if (bcc) {
    mailToLink += `&bcc=${encodeURIComponent(bcc)}`;
  }

  if (body) {
    mailToLink += `&body=${encodeURIComponent(body)}`;
  }

  window.location.href = mailToLink;
};

export const getPortalFilter = (portal, resource) => {
  return _.chain(portal.rules)
    .mapKeys('targetProperty')
    .mapValues((rule) => {
      const value =
        rule.operandType === 'VALUE' ? rule.operand : resource[rule.operand];
      return {
        value: Array.isArray(value) ? value : [value],
        operator: 'or',
      };
    })
    .set('resourcetype', 2)
    .value();
};
