/* eslint-disable func-names */
// eslint func-names: ["error", "always", { "generators": "as-needed" }]

import {
  put,
  call,
  takeLatest,
  select,
  takeEvery,
  spawn,
  takeLeading,
  getContext,
} from 'redux-saga/effects';
import * as _ from 'lodash-es';
import {
  setRepositoryLoading,
  setValidationErrors,
  setSelectedFolder,
  openFolder,
  toggleValidationError,
} from '../../Actions/repository';
import * as actions from '../../Actions/types';
import { getSelectedFolder } from '../../Selectors/repository';
import { validateResources } from 'cosmos-core';
import repository from '../../Api/repository';
import callApi from '../Effects/callApi';
import { notify } from '../../Actions/ui';
import { generateGeneralPreset, resourceToProperties } from '.';
import complete from '../Effects/complete';

function* createSubfolder(folderId, validation, folderType) {
  const selectedFolder = yield select(getSelectedFolder);
  const projectService = yield getContext('projectService');
  const folderProperties = yield call(projectService.getFolderProperties);
  const folderTypeProperties = folderProperties.filter(
    (fp) => fp.folderType == null || fp.folderType === folderType
  );

  if (validation) {
    const validationErrors = validateResources(
      [selectedFolder],
      folderTypeProperties
    );
    if (validationErrors.length > 0) {
      yield put(
        notify(
          'You have errors in some of your documents. Please check all required attributes and resubmit.',
          'user-error'
        )
      );
      yield put(setValidationErrors(validationErrors));
      return;
    }

    yield put(setValidationErrors([]));
  }

  let properties = [];
  try {
    yield put(setRepositoryLoading(true, 'Creating subfolder'));

    const docareaService = yield getContext('docareaService');
    const valuesetsMap = yield select(docareaService.getValuesetsMap);

    properties = resourceToProperties(
      {
        ...selectedFolder,
        foldertype: folderType,
        valid: true,
      },

      folderTypeProperties,
      valuesetsMap
    );

    const id = yield callApi(repository.createSubfolder, folderId, properties);
    const identifier = String(id).split('$')[2];

    return identifier;
  } catch (err) {
    console.error(err);
    // TODO: this is a temporary solution. There needs to be a better overall handling for validation, including the backend error messages
    // Check for existing error

    const existingPropErrMsgPattern =
      /There is already a folder with objectclass '([^']*)' and (.*?) = .*?, only one is allowed\./;

    const errorProperties = properties.filter((prop) => {
      // err.message?.includes(prop.name)
      const match = err.message?.match(existingPropErrMsgPattern);
      if (match) {
        return match[2].trim() === prop.name.trim();
      }
      return false;
    });
    const validationErrors = errorProperties.map((errorProp) => ({
      property: errorProp.name,
      customMessage:
        'This property has to be unique. There might already be an entry with this value. Please check your input.',
    }));
    yield put(setValidationErrors([{ errors: validationErrors }]));
    yield put(notify(err.message, 'error'));
  } finally {
    yield put(setRepositoryLoading(false));
  }
}

function* createSimpleSubfolder(folderId, { folderName, callback }) {
  try {
    yield put(setRepositoryLoading(true, 'Creating subfolder'));

    const properties = [{ name: 'displayname', value: folderName }];

    const id = yield callApi(repository.createSubfolder, folderId, properties);
    const identifier = String(id).split('$')[2];

    if (callback != null) {
      yield spawn(callback, identifier);
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setRepositoryLoading(false));
  }
}

function* saveSubfolderDraft(folderId, action) {
  const selectedFolder = yield select(getSelectedFolder);

  if (selectedFolder == null || selectedFolder.id == null) {
    const identifier = yield call(
      createSubfolder,
      folderId,
      false,
      action.folderType
    );
    yield complete(action, identifier);
  } else {
    yield call(preserveSelectedFolder);
  }
}

function* preserveSelectedFolder() {
  const folder = yield select(getSelectedFolder);
  const projectService = yield getContext('projectService');
  const folderProperties = yield call(projectService.getFolderProperties);

  const folderTypeProperties = folderProperties.filter(
    (fp) => fp.folderType == null || fp.folderType === folder?.foldertype
  );

  const validationErrors = validateResources([folder], folderTypeProperties);
  if (validationErrors.length > 0) {
    yield put(
      notify(
        'You have errors in some of your documents. Please check all required attributes and resubmit.',
        'user-error'
      )
    );
    yield put(setValidationErrors(validationErrors));
    return;
  }

  yield put(setValidationErrors([]));

  try {
    yield put(setRepositoryLoading(true, 'Updating folder properties'));

    const docareaService = yield getContext('docareaService');
    const valuesetsMap = yield select(docareaService.getValuesetsMap);

    const folderToSave = {
      ...folder,
      valid: true,
    };

    const properties = resourceToProperties(
      folderToSave,
      folderTypeProperties,
      valuesetsMap
    );

    yield callApi(repository.updateFolder, folder.id, properties);

    yield put(
      notify(`folder ${folder.displayname} has been saved.`, 'success')
    );

    yield put(openFolder(folder.identifier, { force: true }));
    return folder.identifier;
  } catch (err) {
    console.error(err);
    yield put(notify(err.message, 'error'));
  } finally {
    yield put(setRepositoryLoading(false));
  }
}

function* folderSaga(resourceId) {
  yield takeEvery(
    actions.repository.INITIATE_FOLDER_OF_PRESET,
    function* (action) {
      const { folderType } = action.payload;
      const projectService = yield getContext('projectService');
      const folderProperties = yield call(projectService.getFolderProperties);
      const preset = yield call(generateGeneralPreset, folderProperties);

      yield put(
        setSelectedFolder({
          ...preset,
          foldertype: folderType,
        })
      );

      if (folderProperties.map((p) => p.type).includes('access-management')) {
        const identifier = yield call(
          createSubfolder,
          resourceId,
          false,
          folderType
        );
        yield put(openFolder(identifier));
        yield complete(identifier);
      } else {
        yield complete(action);
      }
    }
  );

  yield takeEvery(
    actions.repository.UPDATE_SELECTED_FOLDER,
    function* ({ payload }) {
      const { propertyName, value } = payload;
      const projectService = yield getContext('projectService');
      const folderProperties = yield call(projectService.getFolderProperties);

      const folderPropertiesMap = _.keyBy(folderProperties, 'name');

      const property = folderPropertiesMap[propertyName];

      if (property?.validate != null) {
        const valid = property.validate(value);
        yield put(toggleValidationError(undefined, propertyName, !valid));
      }
    }
  );

  yield takeLatest(actions.repository.CREATE_SUBFOLDER, function* (action) {
    const identifier = yield call(
      createSubfolder,
      resourceId,
      true,
      action.folderType
    );

    if (identifier != null) {
      yield put(openFolder(identifier));
      yield complete(action, identifier);
    }
  });
  yield takeLatest(
    actions.repository.CREATE_SIMPLE_SUBFOLDER,
    createSimpleSubfolder,
    resourceId
  );
  yield takeLeading(
    actions.repository.SAVE_SUBFOLDER_DRAFT,
    saveSubfolderDraft,
    resourceId
  );
  yield takeLatest(
    actions.repository.PRESERVE_SELECTED_FOLDER,
    function* (action) {
      const identifier = yield call(preserveSelectedFolder);

      if (identifier != null) {
        yield complete(action, identifier);
      }
    }
  );
}

export default folderSaga;
