import * as _ from 'lodash-es';
import { createSelector } from 'reselect';

export const getComments = (state) => state.collaboration.comments;

export const getCommentsCount = createSelector(getComments, (comments) => {
  const flatten = (item) => {
    const innerComments = _.chain(item.comments).flatMapDeep(flatten).value();
    return [item, ...innerComments];
  };

  return _.chain(comments).flatMapDeep(flatten).value().length;
});
