/* eslint-disable func-names */
import * as _ from 'lodash-es';
import { call, put } from 'redux-saga/effects';
import { notify } from '../../Actions/ui';
import callApi from './callApi';

export default (loadingAction, loadingMessage, saga, ...sagaArgs) =>
  call(function* () {
    const notifyLoading = _.isFunction(loadingAction);

    try {
      if (notifyLoading) {
        yield put(loadingAction(true, loadingMessage));
      }

      return yield callApi(saga, ...sagaArgs);
    } catch (err) {
      console.error(err);
      yield put(notify(err.message, 'error'));
    } finally {
      if (notifyLoading) {
        yield put(loadingAction(false));
      }
    }
  });
