/* eslint-disable func-names */
import * as _ from 'lodash-es';
import {
  all,
  call,
  fork,
  getContext,
  put,
  select,
  spawn,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import jsZip from 'jszip';
import {
  resourceCorrectionForGateway,
  resourceCorrectionOfGateway,
} from 'cosmos-config/utils';
import { propertyEquals } from 'cosmos-core';

import documentApi from '../../Api/document';
import resourceApi from '../../Api/resource';
import signatureRequestApi from '../../Api/signatureRequest';
import { selectDocuments } from '../../Actions/repository';
import {
  setDocument,
  setDocumentLoading,
  setOpenedDocumentId,
  setDocumentPreload,
  openDocument as openDocumentAction,
  reloadDocument,
  setDocumentSignatureRequest,
  setSignatureRequestLoading,
} from '../../Actions/document';
import * as actions from '../../Actions/types';
import { notify } from '../../Actions/ui';
import { subscribeResourceEvents } from '../../Actions/websocket';
import {
  setUploadCheckLoading,
  setUploadFiles,
  updateFileHash,
} from '../../Actions/upload';
import { parseGatewayResource } from '../../Utils/documentUtils';
import { blobToFileInfo, trimFileExtension } from '../../Utils/fileUtils';
import { toQueryProperties } from '../../Utils/projectUtils';
import {
  generateDocumentId,
  generateReferenceId,
  generateVersionId,
  generateVersionResourceId,
} from '../../Utils';
import callApi from '../Effects/callApi';
import versionsSaga, { fetchVersionRendition } from './versionsSaga';
import callApiNotify from '../Effects/callApiNotify';
import documentSignRequest from '../../Constants/documentSignRequest';
import { getPrincipalId } from '../../Selectors/userdata';
import complete from '../Effects/complete';
import { getUploadPresets } from '../../Selectors/ui';
import duplicatesCheckerSaga from '../repository/duplicatesCheckerSaga';
import { createContentHashSagas } from '../repository/contentHashSaga';
import docusignConsentSaga from './docusignConsentSaga';

export function* fetchDocumentProperties(documentId) {
  if (documentId == null) {
    console.warn('requested document with id null.');
    return [];
  }

  const projectService = yield getContext('projectService');
  const propertiesMap = yield call(projectService.getProperties);
  const requestedProps = toQueryProperties(propertiesMap);

  try {
    const properties = yield callApi(
      resourceApi.getResourceProperties,
      documentId,
      requestedProps
    );

    const doc = parseGatewayResource(
      { properties, id: documentId },
      propertiesMap
    );

    // try {
    //   const mandatoryProperties = systemProperties.map((p) => p.name);
    //   const docKeys = Object.keys(doc);

    //   mandatoryProperties.forEach((p) => {
    //     if (!docKeys.includes(p)) {
    //       throw new Error(
    //         `Mandatory property ${p} is missing while validating document properties !`
    //       );
    //     }
    //   });
    // } catch (err) {
    //   console.warn(err);
    // }

    // const { objectclass, parentresourceid } = doc;
    // if (parentresourceid != null && parentresourceid !== folderId) {
    // TODO: Proxy folder has to be closed after closing this document.
    // yield put(setProxyFolderId(parentresourceid, objectclass));
    // }

    return doc;
  } catch (err) {
    console.log('Loading document properties failed!');
  }

  return null;
}

function* shareDocument(documentId, action) {
  const { resourceShare, callback } = action;
  try {
    yield callApi(documentApi.shareDocument, documentId, resourceShare);
    yield spawn(callback);

    yield complete(action);

    yield put(notify('The Document was shared.', 'success'));
  } catch (err) {
    yield put(
      notify(`Error while sharing document, reason: ${err.message}`, 'error')
    );
    console.info('Sharing document failed!');
  }
  return null;
}

function* updateDocumentFile(documentId, files, action) {
  if (!Array.isArray(files) || files.length === 0) {
    return;
  }

  try {
    yield put(
      setDocumentLoading(true, 'Uploading new file of document as new version')
    );

    yield callApi(documentApi.uploadDocumentContentItems, documentId, files);

    yield put(notify('Document file has been updated!', 'success'));
    yield put(reloadDocument());
    yield complete(action);
  } catch (err) {
    console.error(err);
    yield put(
      notify(
        `Updating document file failed, please try it again later.`,
        'error'
      )
    );
  } finally {
    yield put(setDocumentLoading(false));
  }
}

function* requestSignature(documentId, action) {
  const { signRequest } = action;
  while (true) {
    try {
      yield put(
        setSignatureRequestLoading(
          true,
          `Creating ${signRequest.draft ? 'draft' : ''} signature request.`
        )
      );

      const data = resourceCorrectionForGateway(
        signRequest,
        documentSignRequest
      );

      if (signRequest.id == null) {
        yield callApi(documentApi.requestSignature, documentId, data);
      } else {
        yield callApi(
          signatureRequestApi.updateSignatureRequest,
          signRequest.id,
          data
        );
      }

      yield put(
        notify(
          signRequest.draft
            ? 'Signature request has been saved'
            : 'Signature was requested.',
          'success'
        )
      );
      yield complete(action);
      yield put(reloadDocument());

      break;
    } catch (err) {
      if (signRequest.provider === 'DOCUSIGN' && err.code === 403) {
        const hasConsent = yield call(docusignConsentSaga);
        if (!hasConsent) {
          break;
        }
      } else {
        yield put(notify(err.message, 'error'));
        yield complete(action, { error: true });

        break;
      }
    } finally {
      yield put(setSignatureRequestLoading(false));
    }
  }
}

function* getDocument(documentIdentifier, force = false, reference = false) {
  const docareaService = yield getContext('docareaService');
  const docareaName = yield call(docareaService.getDocareaName);

  const resourceId = reference
    ? generateReferenceId(docareaName, documentIdentifier)
    : generateDocumentId(docareaName, documentIdentifier);

  if (force) {
    return yield call(fetchDocumentProperties, resourceId);
  }

  const selectedDocuments = yield select(
    (state) => state.repository.selectedDocuments
  );
  const documentInSelected = selectedDocuments.find((d) => d.id === resourceId);

  if (documentInSelected != null) {
    return documentInSelected;
  }

  const documents = yield select((state) => state.repository.documents);
  const documentInMemory = documents.find((d) => d.id === resourceId);

  if (documentInMemory != null) {
    return documentInMemory;
  }

  return yield call(fetchDocumentProperties, resourceId);
}

function* openDocument({
  documentIdentifier,
  type,
  force,
  includePresets,
  reference,
}) {
  if (
    [
      actions.upload.UPLOAD_PROCESS_SUCCESS,
      actions.document.CLOSE_DOCUMENT,
    ].includes(type)
  ) {
    return;
  }

  const preload = yield select((state) => state.document.preload);
  let document;

  if (
    preload != null &&
    preload.identifier === parseInt(documentIdentifier, 10)
  ) {
    document = preload.document;
    // yield put(setDocumentRendition(preload.rendition));
  } else {
    yield put(setDocumentLoading(true, 'Loading document properties'));
    document = yield getDocument(documentIdentifier, force, reference);
    yield put(setDocumentLoading(false));
  }

  if (document == null) {
    return;
  }

  if (includePresets) {
    const uploadPresets = yield select(getUploadPresets);
    const uploadPresetObject = uploadPresets.reduce(
      (acc, cur) => ({ ...acc, [cur.property]: cur.data }),
      {}
    );
    document = {
      ...document,
      ...uploadPresetObject,
    };
  }

  yield put(setDocument(document));

  const documentId = document.id;

  if (type === actions.repository.SCOPE_SELECTED_DOCUMENT) {
    return;
  }

  yield put(subscribeResourceEvents(documentId));

  yield put(selectDocuments([document]));
  yield takeLatest(actions.document.SHARE_DOCUMENT, shareDocument, documentId);
  yield takeLatest(
    actions.document.ADD_FILES_CONTENT_UPDATE,
    function* (action) {
      const { files: fileBlobs } = action.payload;

      const files = fileBlobs.map((file) => blobToFileInfo(file));
      yield put(setUploadFiles(files));

      if (files.length > 0) {
        const currentFileHashMap = yield select(
          (state) => state.upload.fileHashMap
        );

        const hashes = yield call(
          createContentHashSagas,
          fileBlobs,
          function* (generatedHashCount) {
            yield put(
              setUploadCheckLoading(true, {
                loadingStats: {
                  fileContentHashGeneratedCount: generatedHashCount,
                  totalFilesCount: files.length,
                },
              })
            );
          }
        );

        for (const h of hashes) {
          const { fileIdentifier } = h;
          currentFileHashMap[fileIdentifier] = h;
          yield put(updateFileHash(fileIdentifier, h));
        }

        yield call(duplicatesCheckerSaga, currentFileHashMap);

        yield takeLeading(
          actions.document.UPDATE_DOCUMENT_FILE,
          updateDocumentFile,
          documentId,
          fileBlobs
        );
      }
    }
  );

  yield takeLeading(
    actions.document.REQUEST_SIGNATURE,
    requestSignature,
    documentId
  );
  yield takeLatest(actions.document.RELOAD_DOCUMENT, function* () {
    yield put(setOpenedDocumentId(null));
    yield put(openDocumentAction(documentIdentifier, false, reference, true));
  });

  let referencedDocument = document;
  if (reference && document.resourcetype === 3) {
    const referencedDocumentIdentifier = String(document.refresourceid).split(
      '$'
    )[2];
    referencedDocument = yield call(getDocument, referencedDocumentIdentifier);
  }

  const {
    id: referencedId,
    versionidentifier,
    envelopeId,
  } = referencedDocument;

  yield fork(versionsSaga, referencedId, versionidentifier);

  const principalId = yield select(getPrincipalId);

  if (
    (propertyEquals(referencedDocument, 'signaturestatus', 'ACTIVE') ||
      propertyEquals(referencedDocument, 'signaturestatus', 'PARTIAL')) &&
    !_.isEmpty(envelopeId) &&
    propertyEquals(referencedDocument, 'signaturerequestor', principalId)
  ) {
    yield fork(function* () {
      yield takeLeading(
        actions.document.CANCEL_SIGNATURE_REQUEST,
        function* ({ callback }) {
          yield callApiNotify(
            setSignatureRequestLoading,
            'Cancelling Signature Request',
            signatureRequestApi.cancelSignatureRequest,
            envelopeId
          );

          yield put(setDocumentSignatureRequest(null));
          yield spawn(callback);
          yield put(reloadDocument());
        }
      );

      const signatureRequest = yield callApiNotify(
        setSignatureRequestLoading,
        'Loading existing signature request',
        signatureRequestApi.getSignatureRequest,
        envelopeId
      );

      if (signatureRequest != null) {
        yield put(
          setDocumentSignatureRequest(
            resourceCorrectionOfGateway(signatureRequest, documentSignRequest)
          )
        );
      }
    });
  }

  yield put(setOpenedDocumentId(document.identifier));

  if (
    preload == null ||
    preload.identifier !== parseInt(documentIdentifier, 10)
  ) {
    // yield put(fetchDocumentRenditionAction());
  }
}

function* fetchResourceContentItem(
  resourceId,
  { callback, idemIndex, progressCallback, errorCallback }
) {
  try {
    const { data, contentType } = yield callApi(
      documentApi.getDocumentContentItem,
      resourceId,
      idemIndex,
      progressCallback
    );
    const blob = new Blob([data], { type: contentType });
    const rendition = URL.createObjectURL(blob);
    yield spawn(callback, rendition);
  } catch (err) {
    console.error(err);
    yield spawn(errorCallback, err);
  }
}

export default function* documentSaga() {
  yield takeLatest(
    actions.document.PRELOAD_DOCUMENT,
    function* ({ documentIdentifier }) {
      const document = yield call(getDocument, documentIdentifier);

      if (document != null) {
        const { versionidentifier } = document;
        try {
          const rendition = yield call(
            fetchVersionRendition,
            versionidentifier
          );
          yield put(
            setDocumentPreload({
              identifier: documentIdentifier,
              document,
              rendition,
            })
          );
        } catch (err) {
          console.error(err);
        }
      }
    }
  );

  yield takeLatest(
    [
      actions.document.OPEN_DOCUMENT,
      actions.document.CLOSE_DOCUMENT,
      actions.upload.UPLOAD_PROCESS_SUCCESS,
      actions.repository.SCOPE_SELECTED_DOCUMENT,
    ],
    openDocument
  );

  yield takeEvery(
    actions.document.FETCH_VERSION_CONTENT_ITEM,
    function* (action) {
      const { identifier, virtualRendition } = action;

      const docareaService = yield getContext('docareaService');
      const docareaName = yield call(docareaService.getDocareaName);
      const resourceId = virtualRendition
        ? generateVersionId(docareaName, identifier)
        : generateVersionResourceId(docareaName, identifier);

      return yield call(fetchResourceContentItem, resourceId, action);
    }
  );

  yield takeEvery(
    actions.document.FETCH_DOCUMENT_CONTENT_ITEM,
    function* (action) {
      const { identifier } = action;

      const docareaService = yield getContext('docareaService');
      const docareaName = yield call(docareaService.getDocareaName);
      const resourceId = generateDocumentId(docareaName, identifier);

      return yield call(fetchResourceContentItem, resourceId, action);
    }
  );

  yield takeLatest(
    actions.document.FETCH_DOCUMENT_THUMBNAIL,
    function* ({ identifier, callback, errorCallback }) {
      const docareaService = yield getContext('docareaService');
      const docareaName = yield call(docareaService.getDocareaName);
      const resourceId = generateDocumentId(docareaName, identifier);

      try {
        const result = yield callApi(
          documentApi.getDocumentThumbnail,
          resourceId
        );

        if (result?.data != null) {
          const objectUrl = URL.createObjectURL(result.data);
          yield spawn(callback, objectUrl);
        }
      } catch (err) {
        console.error(err);
        yield spawn(errorCallback, err);
      }
    }
  );

  yield takeLatest(
    actions.document.FETCH_DOCUMENT_COMMENT_THUMBNAILS,
    function* ({ payload }) {
      const { identifier, callback, errorCallback } = payload;

      const docareaService = yield getContext('docareaService');
      const docareaName = yield call(docareaService.getDocareaName);
      const resourceId = generateDocumentId(docareaName, identifier);

      try {
        const { data } = yield callApi(
          documentApi.getDocumentCommentThumbnails,
          resourceId
        );

        const zip = yield call(jsZip.loadAsync, data);

        const bloSagas = Object.values(zip.files)
          .filter((f) => !f.dir)
          .map((file) =>
            call(function* () {
              const byteArray = yield call([file, file.async], 'array');
              const blob = new Blob([new Uint8Array(byteArray)], {
                type: 'application/json',
                name: file.name,
              });

              return {
                blobUrl: URL.createObjectURL(blob),
                pageNumber: parseInt(trimFileExtension(file.name), 10),
                contentItemId: 0,
              };
            })
          );

        const results = yield all(bloSagas);

        yield spawn(callback, results);
      } catch (err) {
        console.error(err);
        yield spawn(errorCallback, err);
      }
    }
  );
}
