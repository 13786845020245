import * as _ from 'lodash-es';
import * as actions from '../Actions/types';
import defaultAppearanceSettings from '../Constants/defaultAppearanceSettings';
import userSettingsScope from '../Constants/userSettingsScope';

const initialState = {
  ready: false,
  showSplashScreen: true,
  showWelcomeScreen: false,
  showLogoutScreen: false,
  showGDPRScreen: false,
  userSettings: {
    [userSettingsScope.APPEARANCE]: {
      id: null,
      data: defaultAppearanceSettings,
    },
  },
  notifications: [],
  averageRequestTime: 0,
  statuses: [],
};

const userdata = (state = initialState, action) => {
  switch (action.type) {
    case actions.ui.READY:
      return {
        ...state,
        ready: action.ready,
      };
    case actions.userSettings.SET_USER_SETTINGS:
      if (action.payload.id == null) {
        return {
          ...state,
          userSettings: _.omit(state.userSettings, action.payload.scope),
        };
      }

      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          [action.payload.scope]: {
            id: action.payload.id,
            data: action.payload.data,
          },
        },
      };
    case actions.ui.SET_SHOW_SPLASHSCREEN:
      return {
        ...state,
        showSplashScreen: action.showSplashScreen,
      };
    case actions.ui.SET_SHOW_WELCOME_SCREEN:
      return {
        ...state,
        showWelcomeScreen: action.showWelcomeScreen,
      };
    case actions.ui.SET_SHOW_LOGOUT_SCREEN:
      return {
        ...state,
        showLogoutScreen: action.showLogoutScreen,
      };
    case actions.ui.SET_SHOW_GDPR_SCREEN:
      return {
        ...state,
        showGDPRScreen: action.showGDPRScreen,
      };
    case actions.ui.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
      };
    case actions.ui.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((n) => n.id !== action.id),
      };
    case actions.filterFolder.CLEAR_STORES:
      return {
        ...initialState,
        showSplashScreen: false,
      };
    case actions.ui.STORE_REQUEST_TIME:
      return {
        ...state,
        averageRequestTime: Math.ceil(
          (state.averageRequestTime + action.millis) / 2
        ),
      };
    case actions.ui.SET_STATUSES:
      return {
        ...state,
        statuses: action.statuses,
      };
    default:
      return state;
  }
};

export default userdata;
