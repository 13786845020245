import { call, getContext } from 'redux-saga/effects';
import { utils, writeFile } from 'xlsx';
import { DateTime } from 'luxon';
import { createValuesetMap } from '../../Utils/documentUtils';
import { propertyType, property } from 'cosmos-config/generator';
import * as _ from 'lodash-es';
import { addURLtoResources } from './utils';

export function* exportData(
  data,
  properties,
  sheetName,
  columnNames = [],
  exportRaw = false
) {
  const wb = utils.book_new();
  const ws = yield call(
    createDataSheet,
    data,
    properties,
    columnNames,
    exportRaw
  );

  const sanitizedSheetName = sheetName.replace(/[/\\?%*:|"<>]/g, '_');
  const fileName = `${sanitizedSheetName}_${DateTime.now().toSQLDate()}.xlsx`;

  utils.book_append_sheet(wb, ws, String(sanitizedSheetName).substring(0, 30));
  writeFile(wb, fileName);
}

export function* createDataSheet(
  data,
  properties,
  columnNames = [],
  exportRaw = false
) {
  const columnsToExport =
    columnNames.length > 0
      ? columnNames
      : properties.filter((p) => p.information).map((c) => c.name);

  const columnMap = properties.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.name]: exportRaw ? cur.name : cur.label,
    }),
    {}
  );

  const docareaService = yield getContext('docareaService');
  const valuesetsMap = yield call(docareaService.getValuesetsMap);
  const propertyValuesetMap = createValuesetMap(properties, valuesetsMap);

  const projectService = yield getContext('projectService');
  const projectMembers = yield call(projectService.getMembers);
  const projectMembersMap = _.chain(projectMembers)
    .valuesIn()
    .flatMap()
    .keyBy('principalId')
    .value();

  const getValuesetValue = (propertyName, v) => {
    const vs = propertyValuesetMap[propertyName];
    if (vs != null) {
      const b = vs.find((a) => a.value === v);
      return b != null ? b.label : v;
    }

    return v;
  };

  const resolveLabelFunc = properties
    .filter(
      (p) => columnsToExport.length === 0 || columnsToExport.includes(p.name)
    )
    .map((p) => {
      if (p.type === propertyType.DATE) {
        return {
          name: p.name,
          format: (v) => (v == null ? null : new Date(v)),
        };
      }

      if (p.type === propertyType.SELECT) {
        return {
          name: p.name,
          format: (v) => {
            if (Array.isArray(v)) {
              return v.map((va) => getValuesetValue(p.name, va)).join(', ');
            }

            return getValuesetValue(p.name, v);
          },
        };
      }

      if (
        [propertyType.MEMBER_SELECT, propertyType.PRINCIPAL_SELECT].includes(
          p.type
        )
      ) {
        return {
          name: p.name,
          format: (v) => {
            if (Array.isArray(v)) {
              return v
                .map((va) =>
                  p.cellRender(va, projectMembersMap[va]?.displayName)
                )
                .join(',');
            }

            return p.cellRender(v, projectMembersMap[v]?.displayName);
          },
        };
      }

      return {
        name: p.name,
        format: p.format,
      };
    })
    .reduce((acc, cur) => ({ ...acc, [cur.name]: cur.format }), {});

  const documentsToExport = data.map((d) => {
    return columnsToExport.reduce((acc, key) => {
      const label = columnMap[key] != null ? columnMap[key] : key;
      const value = _.get(d, key);

      if (exportRaw) {
        return {
          ...acc,
          [key]: value,
        };
      }

      const formattedValue =
        resolveLabelFunc[key] != null ? resolveLabelFunc[key](value) : value;

      return {
        ...acc,
        [label]: Array.isArray(formattedValue)
          ? formattedValue.join()
          : formattedValue,
      };
    }, {});
  });

  const options = {
    header: columnsToExport
      .map((columnName) =>
        columnMap[columnName] != null ? columnMap[columnName] : columnName
      )
      .filter((c) => c != null),
    cellDates: true,
  };

  return utils.json_to_sheet(documentsToExport, options);
}

export function* exportDocumentsExcel(documents, columnNames = []) {
  const projectService = yield getContext('projectService');
  const project = yield call(projectService.getOpenedProject);
  const properties = yield call(projectService.getProperties);
  const columns = yield call(projectService.getTableColumns);
  const columnsToExport =
  columnNames.length === 0 ? columns.map((c) => c.name) : columnNames;

  const columnsWithUrl = [...columnsToExport, 'url'] || [];
  const {resourcesWithUrls} = yield call(addURLtoResources, documents);

  return yield call(
    exportData,
    resourcesWithUrls,
    properties,
    project?.name,
    columnsWithUrl
  );
}

export function* exportTitleUrlExcel(resources) {
  const {resourcesWithUrls, project} = yield call(addURLtoResources, resources);

  return yield call(
    exportData,
    resourcesWithUrls,
    [
      property('Name', 'displayname').informational(),
      property('Url', 'url').informational(),
    ],
    project?.name,
    []
  );
}
