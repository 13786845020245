import * as _ from 'lodash-es';
import * as actions from '../Actions/types';

const initialState = {
  comments: [],
  latestChatComments: [],
  reviewComments: [],
  loading: false,
  teamspaces: [],
  teamspaceFolders: [],
  teamspacesLoading: false,
  selectedTeamspace: null,
  teamspaceMembers: [],
  teamspaceMembersLoading: false,
};

const insertComment = (comment, comments) => {
  const commentsMap = _.keyBy(comments, 'id');

  let results = [];

  if (commentsMap[comment.id] == null) {
    const number = (_.chain(comments).map('number').max() || 0) + 1;
    results = [
      {
        ...comment,
        number,
      },
      ...comments,
    ];
  } else {
    results = comments.map((c) => {
      if (c.id === comment.id) {
        return comment;
      }

      return c;
    });
  }

  return results.filter((r) => r.status !== 'deleted');
};

const addCommentRecursive = (comment, comments) => {
  if (comment.parentId == null) {
    return insertComment(comment, comments);
  }

  return Array.isArray(comments)
    ? comments.map((c) => ({
        ...c,
        comments:
          comment.parentId === c.id
            ? insertComment(comment, c.comments)
            : addCommentRecursive(comment, c.comments),
      }))
    : null;
};

const workflow = (state = initialState, action) => {
  switch (action.type) {
    case actions.comment.FETCH_COMMENTS:
    case actions.comment.RELOAD_COMMENTS:
      return {
        ...state,
        loading: true,
      };
    case actions.filterFolder.SET_COLLABORATION_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.comment.SET_COMMENTS:
      return {
        ...state,
        comments: action.comments,
        loading: false,
      };
    case actions.websocket.WS_RECEIVED_COMMENT:
      return {
        ...state,
        comments: addCommentRecursive(action.comment, state.comments),
      };
    case actions.comment.SET_LATEST_CHAT_COMMENTS:
      return {
        ...state,
        latestChatComments: action.comments,
      };
    case actions.teamspaces.CLEAR_TEAMSPACE_DATA:
    case actions.filterFolder.CLEAR_STORES:
      return {
        ...initialState,
      };
    case actions.teamspaces.SET_TEAMSPACES:
      return {
        ...state,
        teamspaces: action.teamspaces,
      };
    case actions.teamspaces.SET_SELECTED_TEAMSPACE:
      return {
        ...state,
        selectedTeamspace: action.teamspace,
      };
    case actions.teamspaces.UPDATE_SELECTED_TEAMSPACE:
      return {
        ...state,
        selectedTeamspace: {
          ...state.selectedTeamspace,
          [action.propertyName]: action.value,
        },
      };
    case actions.teamspaces.SET_TEAMSPACE_FOLDERS:
      return {
        ...state,
        teamspaceFolders: action.folders,
      };
    case actions.teamspaces.SET_TEAMSPACES_LOADING:
      return {
        ...state,
        teamspacesLoading: action.loading,
      };
    case actions.teamspaces.SET_TEAMSPACE_MEMBERS:
      return {
        ...state,
        teamspaceMembers: action.principalIds,
      };
    case actions.teamspaces.SET_TEAMSPACE_MEMBERS_LOADING:
      return {
        ...state,
        teamspaceMembersLoading: action.loading,
      };
    default:
      return state;
  }
};

export default workflow;
