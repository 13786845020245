import * as _ from 'lodash-es';
import { projectService } from 'cosmos-core';

const buildGroups = (groups) => {
  return groups.filter((g) => g.group).map((group) => group.build());
};

const buildProperties = (groups) => {
  return groups
    .filter((p) => !p.structure)
    .map((p) => {
      if (p.group) {
        return p.buildChildren();
      }

      return [p, ...p.buildChildren()];
    })
    .reduce((acc, cur) => [...acc, ...cur], []);
};

const buildDedicatedGroups = (groups, taskName) => {
  return groups.filter(
    (gp) => gp.dedicated == null || gp.dedicated === taskName
  );
};

/**
 * Get properties groups for a nscale workflow by process definition.
 *
 * @param {string} processDefinition - Process definition of the workflow.
 * @returns {Group[]} - List of grouped properties.
 */
const getWorkflowGroups = (processDefinition) => {
  if (processDefinition == null) {
    return [];
  }

  const groups = projectService.getNscaleWorkflowProperties(processDefinition);

  return buildGroups(groups);
};

/**
 * Get properties for a nscale workflow by process definition.
 *
 * @param {string} processDefinition - Process definition of the workflow.
 * @returns {Property[]} - List of workflow properties.
 */
const getWorkflowProperties = (processDefinition = '') => {
  const workflowGroups = getWorkflowGroups(processDefinition);
  return buildProperties(workflowGroups);
};

/**
 * Get properties for a nscale workflows by multiple process definitions.
 *
 * @param {string[]} processDefinitions - List of process definitions of the workflows.
 * @returns {Property[]} - List of workflow properties.
 */
const getAllWorkflowsProperties = (processDefinitions) => {
  return _.chain(processDefinitions)
    .flatMap(getWorkflowProperties)
    .uniqBy('name')
    .value();
};

/**
 * Get properties for a nscale workflow task by process definition and task name.
 *
 * @param {string} processDefinition - Process definition of the workflow.
 * @param {string} taskName - Name of the task.
 * @returns {Property[]} - List of workflow task properties.
 */
const getTaskProperties = (processDefinition, taskName) => {
  const workflowGroups = getWorkflowGroups(processDefinition);
  return buildDedicatedGroups(workflowGroups, taskName);
};

const localPropertiesService = {
  getAllWorkflowsProperties,
  getWorkflowGroups,
  getWorkflowProperties,
  getTaskProperties,
};

export default localPropertiesService;
